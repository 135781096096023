// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scheduleContainer {
  background-color: #ffffff;
  padding: 0 1rem 1rem;
}
.scheduleContainer .title {
  font-size: 1.25rem;
  text-align: center;
  color: rgb(80, 80, 80);
}
.scheduleContainer .dayList {
  font-size: 1rem;
}
.scheduleContainer .dayList .scheduleItem {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}
.scheduleContainer .dayList .scheduleItem .scheduleRange {
  display: block;
  margin-bottom: 0.25rem;
}
.scheduleContainer .dayList .dayOff {
  color: rgb(150, 150, 150);
}`, "",{"version":3,"sources":["webpack://./src/components/WorkHours/WorkHours.scss","webpack://./src/assets/Theme/variables/_colorSchema.scss"],"names":[],"mappings":"AAEA;EACE,yBCHM;EDIN,oBAAA;AADF;AAEE;EACE,kBAAA;EACA,kBAAA;EACA,sBCCa;ADDjB;AAEE;EACE,eAAA;AAAJ;AACI;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;AACN;AAAM;EACE,cAAA;EACA,sBAAA;AAER;AACI;EACE,yBChBO;ADiBb","sourcesContent":["@import '../../assets/Theme/variables/colorSchema';\n\n.scheduleContainer {\n  background-color: $white;\n  padding: 0 1rem 1rem;\n  .title {\n    font-size: 1.25rem;\n    text-align: center;\n    color: $unselectedText;\n  }\n  .dayList {\n    font-size: 1rem;\n    .scheduleItem {\n      margin: 0.5rem 0;\n      display: flex;\n      justify-content: space-between;\n      .scheduleRange {\n        display: block;\n        margin-bottom: 0.25rem;\n      }\n    }\n    .dayOff {\n      color: $msgDisable;\n    }\n  }\n}\n","$white: #ffffff;\n$text_dark: #2c2c2e;\n$text_gray: rgb(178, 181, 188);\n$buttonBg: #363c56;\n$errorText: #f70d1a;\n$buttonLinearBg: linear-gradient(to right, #1d2239, #4657a2, #1d2239);\n$msgDisable: rgb(150, 150, 150);\n$disabled: rgb(200, 200, 200);\n$light_gray: rgb(241, 241, 241);\n$unselectedText: rgb(80, 80, 80);\n$locationBnt: rgb(255, 255, 0);\n$spinner_blue: #3498db;\n\n$bg_unselected: rgb(188, 188, 224);\n$bg_selected: #559f5b;\n$bg_pending: #ccb137;\n$bg_frame: rgba(0, 0, 0, 0.6);\n\n$shadow: #2c2c2e5e;\n$light_shadow: rgba(49, 49, 52, 0.2);\n$highlighter: #eeff6e;\n$appt_bg: rgb(249, 249, 249);\n$red_bg: #cb101a;\n\n$notification_success: #4fae00;\n$notification_warning: #e4dd00;\n$notification_error: #be1000;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
