import { LocalStorageService, get } from '../services';

import { LocalStorageKeys } from '../enums';
import type { TUserData } from '../types';
import { activeRequestsAtom } from '../../atoms';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

export function useGetUserProfile() {
  const setActiveRequests = useSetRecoilState(activeRequestsAtom);

  const getUserData = useCallback(async () => {
    if (!LocalStorageService.getItem(LocalStorageKeys.AccessToken)) return;
    setActiveRequests(prev => prev + 1);
    const { data, error } = await get('USER_PROFILE');
    setActiveRequests(prev => prev - 1);
    if (!!error) {
      console.log(error);
      return null;
    } else {
      return data as TUserData;
    }
  }, [setActiveRequests]);

  return { getUserData };
}
