import type { TFullExpertData } from '../utils/types';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const fullDataAtom = atom<TFullExpertData | null>({
  key: 'fullDataState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
