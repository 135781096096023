import './LoginBUtton.scss';

import { AbsoluteRoutes, LocalStorageKeys, LocalStorageService, getCapitalizeWord, useTranslation } from '../../utils';

import { useNavigate } from 'react-router-dom';

const LoginButton = () => {
  const token = LocalStorageService.getItem(LocalStorageKeys.AccessToken);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToLogin = () => {
    navigate(AbsoluteRoutes.login);
  };

  if (!!token) return <></>;

  return (
    <button className='loginBtn' onClick={goToLogin}>
      {getCapitalizeWord(t('Login.Button_login'))}
    </button>
  );
};

export default LoginButton;
