import './ExpertInfo.scss';

import defaultExpertAvatar from '../../assets/images/entrepreneurs.png';
import { useGetExpertData } from '../../utils';

const ExpertInfo = () => {
  const { expertInfo, expertAvatar } = useGetExpertData();

  return (
    <div className='expertInfo'>
      <img
        src={!!expertAvatar ? `data:image/png;base64,${expertAvatar}` : defaultExpertAvatar}
        alt='expert-avatar'
        className='avatar'
      />
      <h2>{expertInfo?.userName}</h2>
      <p className='title'>{expertInfo?.profession}</p>
      <p>{expertInfo?.email}</p>
    </div>
  );
};

export default ExpertInfo;
