import './MoveButtons.scss';

import { getLocalTimeString, getMovedTimeString } from '../../utils';

import type { TMoveButtonsProps } from '../../utils/types';

const MoveButtons = ({ moveAppt, onMoveButtonClick, value }: TMoveButtonsProps) => {
  return (
    <>
      <p>select a start time</p>
      <div className='moveButtons'>
        <button
          data-move-by='-30'
          className={moveAppt === -30 ? 'selected' : ''}
          disabled={!value.moveBack}
          onClick={onMoveButtonClick}>
          {getLocalTimeString(getMovedTimeString(value.starts, -30))}
        </button>
        <button data-move-by='0' className={moveAppt === 0 ? 'selected' : ''} onClick={onMoveButtonClick}>
          {getLocalTimeString(value.starts)}
        </button>
        <button
          data-move-by='30'
          className={moveAppt === 30 ? 'selected' : ''}
          disabled={!value.moveForward}
          onClick={onMoveButtonClick}>
          {getLocalTimeString(getMovedTimeString(value.starts, 30))}
        </button>
      </div>
    </>
  );
};

export default MoveButtons;
