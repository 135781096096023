import './TimeSelector.scss';

import { getLocalTimeString, getMovedTimeString, useTranslation } from '../../utils';
import { useEffect, useState } from 'react';

import { MoveButtons } from '../MoveButtons';
import type { TTimeSelectorProps } from '../../utils/types';
import { useHandlers } from './hooks';

const TimeSelector = ({ availableTimes, onTimeSelect, value, selectedService }: TTimeSelectorProps) => {
  const [moveAppt, setMoveAppt] = useState<number>(0);

  const { t } = useTranslation();
  const { createAppointment, onMoveButtonClick } = useHandlers({
    facilityId: selectedService.id,
    value,
    moveAppt,
    setMoveAppt,
  });

  useEffect(() => {
    setMoveAppt(0);
  }, [value]);

  return (
    <div className='timeSelector'>
      <h3>{t('Create appointment (client).Text_select_time_slot_web')}</h3>
      {!!availableTimes.length ? (
        <>
          <div className='list'>
            {availableTimes.map((slot, index) => (
              <div
                className={`timeSlot ${value?.starts === slot.starts ? 'selected' : ''}`}
                key={index}
                onClick={() => onTimeSelect(slot)}>
                {value?.starts === slot.starts ? (
                  <>
                    {getLocalTimeString(getMovedTimeString(slot.starts, moveAppt))} -
                    {getLocalTimeString(getMovedTimeString(slot.ends, moveAppt))}
                  </>
                ) : (
                  <>
                    {getLocalTimeString(slot.starts)} - {getLocalTimeString(slot.ends)}
                  </>
                )}
              </div>
            ))}
          </div>
          {!!value && selectedService.duration !== 'PT30M' && (
            <MoveButtons value={value} moveAppt={moveAppt} onMoveButtonClick={onMoveButtonClick} />
          )}
        </>
      ) : (
        <p>{t('Appointment(no free time).Text_field')}</p>
      )}
      <button className='submitBtn' onClick={createAppointment} disabled={!value}>
        {t('Create appointment (client).Text_create_appointment')}
      </button>
    </div>
  );
};

export default TimeSelector;
