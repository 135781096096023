import { useCallback, useEffect } from 'react';

import { LocalStorageKeys } from '../enums';
import { LocalStorageService } from '../services';
import type { TFullExpertData } from '../types';
import { URLS } from '../constants';
import { activeRequestsAtom } from '../../atoms';
import axios from 'axios';
import { fullDataAtom } from '../../atoms/fullDataAtom';
import { useSetRecoilState } from 'recoil';

export function useGetFullData() {
  const setFullExpertData = useSetRecoilState(fullDataAtom);
  const setActiveRequests = useSetRecoilState(activeRequestsAtom);

  const fetchFullData = useCallback(() => {
    const uuid = LocalStorageService.getItem(LocalStorageKeys.Uuid);
    setActiveRequests(prev => prev + 1);
    // need to handle case when we don`t have an UUID
    axios
      .get(`${URLS.API_URL}${URLS.INVITE_INFO}${uuid}`)
      .then(res => {
        const { data } = res as { data: TFullExpertData };
        setFullExpertData(data);
      })
      .catch(error => console.log(error))
      .finally(() => setActiveRequests(prev => prev - 1));
  }, [setActiveRequests, setFullExpertData]);

  useEffect(() => {
    fetchFullData();
    // need only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
