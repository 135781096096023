import { AbsoluteRoutes, LocalStorageKeys, LocalStorageService } from '../../utils';

import { Navigate } from 'react-router-dom';
import type { TRouteGuard } from '../../utils/types';

const AuthRoute = ({ element }: TRouteGuard) => {
  const token = LocalStorageService.getItem(LocalStorageKeys.AccessToken);

  if (token) {
    return <Navigate to={AbsoluteRoutes.expert} />;
  }

  return element;
};

export default AuthRoute;
