import { TPopUpHandlersProps } from '../../../utils/types';
import { useCallback } from 'react';

export function useHandlers({ setState }: TPopUpHandlersProps) {
  const handleClose = useCallback((): void => {
    setState({ popup: null });
  }, [setState]);

  const handleClick = useCallback(
    (action: () => void): void => {
      action();
      handleClose();
    },
    [handleClose],
  );

  return { handleClose, handleClick };
}
