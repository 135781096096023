export const languagesObject = {
  en: 'English',
  uk: 'Ukrainian',
  pl: 'Polish',
  ru: 'Russian',
  fr: 'French',
  pt: 'Portuguese',
  es: 'Spanish',
} as const;

export const calendarLocales = {
  English: 'en-US',
  Ukrainian: 'uk',
  Polish: 'pl',
  Russian: 'ru',
  French: 'fr',
  Portuguese: 'pt',
  Spanish: 'es',
} as const;
