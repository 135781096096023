import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { LocalStorageKeys } from '../enums';
import { LocalStorageService } from '../services';
import type { TExpertData } from '../types';
import { activeRequestsAtom } from '../../atoms';
import { fullDataAtom } from '../../atoms/fullDataAtom';

export function useGetExpertData() {
  const [expertInfo, setExpertInfo] = useState<TExpertData | null>(null);
  const [expertAvatar, setExpertAvatar] = useState<string | null>(null);
  const fullExpertData = useRecoilValue(fullDataAtom);

  const setActiveRequests = useSetRecoilState(activeRequestsAtom);

  const getFullDataFromStorage = useCallback(() => {
    setActiveRequests(prev => prev + 1);
    if (!fullExpertData) {
      console.log('need to handle case when full data is null');
      setActiveRequests(prev => prev - 1);
      return;
    }
    LocalStorageService.setItem(LocalStorageKeys.TimeZone, fullExpertData.expertProfile.zoneId);
    setExpertInfo(fullExpertData.expertProfile);
    setExpertAvatar(fullExpertData.imageBase64);
    setActiveRequests(prev => prev - 1);
  }, [fullExpertData, setActiveRequests]);

  useEffect(() => {
    getFullDataFromStorage();
    // only when mounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { expertInfo, expertAvatar };
}
