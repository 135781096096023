import * as yup from 'yup';

export const signUpValidationSchema = yup.object().shape({
  fullName: yup.string().required('Full name is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  cityCode: yup.number().required('City is required'),
  email: yup.string().email('Invalid email format'),
});

export const phoneNumberValidationSchema = yup.object({
  phoneNumber: yup.string().required('Phone number is required'),
});
