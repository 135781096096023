import './CitySelector.scss';

import type { TCities, TCitiesSelectorProps } from '../../utils/types';
import { useData, useHandlers } from './hooks';
import { useEffect, useState } from 'react';

import SearchInput from '../SearchInput/SearchInput';
import { highlightText } from '../../utils/helpers/highlightText';
import { useTranslation } from '../../utils';

const CitySelector = ({ options, field, form, initialValue }: TCitiesSelectorProps) => {
  const [loadedItems, setLoadedItems] = useState(options.slice(0, 49));

  const { t } = useTranslation();
  const { isOpen, openSelect, handleSearchInputChange, selectedValue, searchValue, onOptionClick } = useHandlers({
    form,
    field,
    initialValue,
  });
  const { filteredOptions, handleScroll } = useData({ options, searchValue, loadedItems, setLoadedItems });

  useEffect(() => {
    if (isOpen) {
      const nextPage = loadedItems.length / 50 + 1;
      const newItems = filteredOptions?.slice(0, nextPage * 50);
      setLoadedItems(newItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, filteredOptions]);

  return (
    <>
      <>
        <label htmlFor='city'>{t('Sign up (client).Select_city')}</label>
        <input className='form-control' name='city' id='city' onClick={openSelect} value={selectedValue} readOnly />
      </>
      {isOpen && (
        <div className='portal' onScroll={handleScroll}>
          <div className='popupBox'>
            <div className='searchWrapper'>
              <SearchInput handleChangeSearchValue={handleSearchInputChange} />
            </div>
            <div className='listContainer'>
              {loadedItems.map((option: TCities, index: number) => (
                <div className='listItem' key={index} onClick={onOptionClick(option.name, option.cityId)}>
                  <span>{highlightText(option.name, searchValue, 'highlight')}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CitySelector;
