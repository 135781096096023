import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';

import * as pickerLocales from 'date-fns/locale';

import { getCalendarLocaleKey, useTranslation } from '../../utils';
import { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import type { TCalendarProps } from '../../utils/types';
import { availableDayArray } from '../../utils/constants/temporaries';

const isWeekday = (value: Date) => {
  const day = value.getDay();
  return availableDayArray.includes(day);
};

const Calendar = ({ date, setDate }: TCalendarProps) => {
  const [locale, setLocale] = useState(pickerLocales.enUS);

  const { t } = useTranslation();

  useEffect(() => {
    const userLocale = getCalendarLocaleKey() as keyof typeof pickerLocales;
    const localeToSet = pickerLocales[userLocale];
    setLocale(localeToSet);
  }, []);

  return (
    <div className='datePicker'>
      <h3>{t('Create appointment (client).Text_select_date_web')}</h3>
      <DatePicker
        selected={date}
        onChange={setDate}
        filterDate={isWeekday}
        inline
        monthsShown={1}
        minDate={new Date()}
        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
        locale={locale}
      />
    </div>
  );
};

export default Calendar;
