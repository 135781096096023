import { useCallback, useEffect } from 'react';

import type { TTranslationsResponse } from '../types';
import { get } from '../services';
import { getLanguageFromLocale } from '../helpers';
import { translationsState } from '../../atoms/translationsAtom';
import { useSetRecoilState } from 'recoil';

export function useFetchTranslation() {
  const setTranslations = useSetRecoilState(translationsState);
  const language = getLanguageFromLocale();

  const fetchTranslation = useCallback(async () => {
    try {
      const { data, error } = await get('TRANSLATION', language);

      if (error) {
        return null;
      }

      if (data) {
        const { translations } = data as TTranslationsResponse;
        return translations[language] || null;
      }

      return null;
    } catch (error) {
      return null;
    }
  }, [language]);

  useEffect(() => {
    const loadTranslations = async () => {
      const translations = await fetchTranslation();
      if (translations) {
        setTranslations(translations);
      }
    };

    loadTranslations();
    // only for CDM
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
