import { LocalStorageKeys } from '../enums';
import { LocalStorageService } from '../services';

export const DEFAULT_TIME_ZONE = 'UTC';

export const LOCALE_DATE_FORMAT = 'yyyy-MM-dd';
export const LOCALE_TIME_FORMAT = 'HH:mm';
export const LOCALE_DATE_WITH_TIME_FORMAT = 'yyyy-MM-dd HH:mm';

export const timeZone = LocalStorageService.getItem(LocalStorageKeys.TimeZone) || 'America/New_York';
export const is12hourFormat = timeZone.includes('America');
