import './FacilityList.scss';

import { parseDurationToUIString, useGetFacilities, useTranslation } from '../../utils';

const FacilityList = () => {
  const { facilities } = useGetFacilities();
  const { t } = useTranslation();

  if (!facilities) return <></>;

  return (
    <div className='facility'>
      <h3 className='title'>{t('Settings (expert).Tab_services')}</h3>
      <div className='facilityList'>
        {facilities.map((service, index) => {
          return (
            <div className='listItem' key={index}>
              <div className='itemTitle'>
                <span>{service.facility}</span>
                <span>{parseDurationToUIString(service.duration)}</span>
              </div>
              <span className='location'>{service.geolocation?.fullAddress}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FacilityList;
