import './VerificationCode.scss';

import React, { useMemo, useReducer, useRef } from 'react';
import type { TVerificationCode, TVerificationCodeProps } from '../../utils/types';
import { useTranslation, verificationCodeInitialValues } from '../../utils';

import { useHandlers } from './hooks';

const VerificationCode = ({ onVerify, setErrorMessage, errorMessage }: TVerificationCodeProps) => {
  const [code, dispatch] = useReducer(
    (s: TVerificationCode, a: Partial<TVerificationCode>): TVerificationCode => ({ ...s, ...a }),
    verificationCodeInitialValues,
  );
  // Array of input references
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

  const { t } = useTranslation();
  const { hideErrorMessage, handleInputChange, handleVerify } = useHandlers({
    inputRefs,
    errorMessage,
    setErrorMessage,
    code,
    dispatch,
    onVerify,
  });

  const inputClassName = useMemo(() => `code-input ${!!errorMessage ? 'error' : ''}`, [errorMessage]);

  return (
    <>
      <div className='verificationCode'>
        <label htmlFor='code'>Enter Verification Code</label>
        <div className='code-inputs'>
          {Array.from({ length: 6 }, (_, index) => {
            const fieldName = `codeDigit${index + 1}` as keyof TVerificationCode;
            return (
              <div key={index}>
                <input
                  type='text'
                  maxLength={1}
                  className={inputClassName}
                  inputMode='numeric'
                  pattern='[0-9]*'
                  ref={(el: HTMLInputElement | null) => (inputRefs.current[index] = el)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
                  value={code[`codeDigit${index + 1}` as keyof TVerificationCode] || ''}
                  name={fieldName}
                  onFocus={hideErrorMessage}
                  onClick={() => inputRefs.current[index]?.select()}
                />
              </div>
            );
          })}
        </div>
        <p className='error-message'>{errorMessage}</p>
      </div>
      <button onClick={handleVerify} className='verificationCodeBtn'>
        {t('Verification 2.Button_verifiy')}
      </button>
    </>
  );
};

export default VerificationCode;
