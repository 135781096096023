import type { TDraftAppt } from '../utils/types';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const draftApptAtom = atom<TDraftAppt | null>({
  key: 'draftApptState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
