import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { TFacilities } from '../types';
import { activeRequestsAtom } from '../../atoms';
import { fullDataAtom } from '../../atoms/fullDataAtom';

export function useGetFacilities() {
  const [facilities, setFacilities] = useState<TFacilities[] | null>(null);
  const setActiveRequests = useSetRecoilState(activeRequestsAtom);
  const fullExpertData = useRecoilValue(fullDataAtom);

  const getFacilities = useCallback(async () => {
    if (!fullExpertData) {
      return;
    }
    setActiveRequests(prev => prev + 1);
    const { expertFacilities } = fullExpertData;

    // remote services was hidden
    setFacilities(expertFacilities.expertServices.filter(service => !!service.geolocation?.fullAddress));
    // }
    setActiveRequests(prev => prev - 1);
  }, [fullExpertData, setActiveRequests]);

  useEffect(() => {
    getFacilities();
    // only when mounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { facilities };
}
