import { AbsoluteRoutes } from '../../../utils';
import type { TApptListHandlersProps } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';

export function useHandlers({ apptList }: TApptListHandlersProps) {
  const navigate = useNavigate();

  const onApptClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const apptId = event.currentTarget.getAttribute('data-appt-id');

    const selectedAppointment = apptList.find(appt => appt.appointmentId === Number(apptId));

    !!selectedAppointment && navigate(AbsoluteRoutes.appointment, { state: { selectedAppointment } });
  };

  return { onApptClick };
}
