export const URLS = {
  API_AUTH: 'https://api.ea-auth.dominusse.com/',
  API_URL: 'https://api.ea.dominusse.com/api/',

  // INVITATION ROUT (USING "API_URL")
  INVITE_INFO: 'user/expert/external?id=',

  // AUTH
  LOGIN: 'login',
  VALIDATION: 'login/confirm?devicePlatform=ANDROID',
  REFRESH: 'refresh',

  // API
  CITIES: 'ref/citiesByCountry/',
  TRANSLATION: 'uilabel?language=',

  // USER
  REGISTRATION: 'user/registration',
  USER_PROFILE: 'user/retrieveProfileData',
  EXPERT_PROFILE: 'user/retrieveUserData',
  WORKHOURS: 'workhours',
  FACILITIES: 'expertFacilities',
  AVATAR: 'images/download',
  CONTACT_CHECK: 'contacts/check',
  FREE_HOURS: 'workhours/getFreeHours',

  // APPOINTMENT
  CREATE_APPOINTMENT: 'appointment/create',
  LIST_APPOINTMENT: 'appointment/list',
  UPDATE_APPOINTMENT_STATUS: 'appointment/updateStatus',
};
