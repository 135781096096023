import './ServiceSelector.scss';

import { MouseEvent, useCallback, useState } from 'react';
import { getCapitalizeWord, parseDurationToUIString, useGetFacilities, useTranslation } from '../../utils';

import { AbsoluteRoutes } from '../../utils/enums';
import { NextButton } from '../NextButton';
import type { TFacilities } from '../../utils/types';

const ServiceSelector = () => {
  const [selectedService, setSelectedService] = useState<TFacilities>();

  const { t } = useTranslation();
  const { facilities } = useGetFacilities();

  const handleSelectService = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const id = e.currentTarget.getAttribute('data-service-id');
      if (id) {
        const selectedFacility = facilities?.find(item => item.id === Number(id));
        setSelectedService(selectedFacility);
      }
    },
    [facilities],
  );

  return (
    <div className='serviceSelector'>
      <h3>{t('Confirmation.Select_expert_service_web')}</h3>
      {facilities?.map((service, i) => {
        const selected = selectedService?.id === service.id;
        return (
          <div
            className={`service ${selected ? 'selected' : ''}`}
            data-service-id={service.id}
            key={i}
            onClick={handleSelectService}>
            <div>
              <span>{t('Confirmation.Text_service')}</span>
              <span className='title'>{service.facility}</span>
            </div>
            <div>
              <span>{t('Settings (expert).Text_duration')}</span>
              <span>{parseDurationToUIString(service.duration)}</span>
            </div>
            <div>
              {selected && service.geolocation ? (
                <button className='locationBtn' onClick={() => console.log('click in location BTN')}>
                  {t('Settings (expert).Text_location')}
                </button>
              ) : (
                <span>{t('Settings (expert).Text_location')}</span>
              )}
              <span className='location'>{service.geolocation.fullAddress}</span>
            </div>
          </div>
        );
      })}
      <NextButton
        link={AbsoluteRoutes.booking}
        navState={{ facility: selectedService }}
        btnText={getCapitalizeWord(t('Select role.Button_continue'))}
        isDisable={!selectedService}
      />
    </div>
  );
};

export default ServiceSelector;
