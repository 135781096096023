export enum AbsoluteRoutes {
  invite = '/invite/:id',
  expert = '/expert',
  booking = '/booking',
  signup = '/signup',
  verificationCode = '/verify',
  login = '/login',
  services = '/facility',
  appointment = '/appointment',
}
