import { calendarLocales, languagesObject } from '../constants';

import type { TShortLanguageName } from '../types';

export const getLanguageFromLocale = () => {
  const languageCode = navigator.language.split('-')[0] as TShortLanguageName;

  let language: TShortLanguageName = 'en';

  if (Object.keys(languagesObject).includes(languageCode)) {
    language = languageCode;
  }

  return languagesObject[language];
};

export const getCalendarLocaleKey = () => {
  const language = getLanguageFromLocale();
  return calendarLocales[language];
};
