import { translationsState } from '../../atoms';
import { useRecoilValue } from 'recoil';

export function useTranslation() {
  const translations = useRecoilValue(translationsState);

  const t = (key: string): string => {
    if (!translations) return key;

    const keys = key.split('.');
    let result: unknown = translations;

    for (let i = 0; i < keys.length; i++) {
      if (typeof result === 'object' && result !== null && keys[i] in result) {
        result = (result as Record<string, unknown>)[keys[i]];
      } else {
        return key;
      }
    }

    return typeof result === 'string' ? result : key;
  };

  return { t };
}
