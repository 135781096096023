import { LOCALE_DATE_FORMAT, LOCALE_TIME_FORMAT, timeZone } from '../constants/date';
import type { TConvertedFreeHours, TFacilities, TFreeHours, TFreeTimeSlot } from '../types';
import { addDays, endOfDay, format, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { getAvailableTimeSlots, getTimeInMinutes } from './timeHelpers';

import { toZonedTime } from 'date-fns-tz';

const parseTimeStringUTC = (timeString: string, date: Date) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes));
};

const convertUTCToLocal = (utcDate: Date) => {
  return toZonedTime(utcDate, timeZone);
};

const roundToNearestHour = (date: Date) => {
  const minutes = date.getMinutes();
  const roundedMinutes = minutes % 2 === 0 ? minutes : 60; // Round to nearest hour (0 or 60 minutes)
  return new Date(date.setMinutes(roundedMinutes, 0, 0)); // Set minutes to 0 or 60 and seconds to 0
};

const splitTimeRange = (start: Date, end: Date, currentDate: Date) => {
  const dayStart = startOfDay(currentDate);
  const dayEnd = endOfDay(currentDate);

  const localStart = convertUTCToLocal(start);
  const localEnd = convertUTCToLocal(end);

  const roundedEnd = roundToNearestHour(localEnd);

  const result: { start: Date; end: Date; day: Date }[] = [];

  // If the initial time is before the beginning of the day, set it to the beginning of the day
  if (isBefore(localStart, dayStart)) {
    result.push({
      start: dayStart,
      end: isBefore(roundedEnd, dayEnd) ? roundedEnd : dayEnd,
      day: dayStart,
    });
    start = addDays(dayStart, 1); // Move the start time to the next day
  }

  // If the final time is after the end of the day, we divide it into the next day
  if (isAfter(roundedEnd, dayEnd)) {
    if (isAfter(localStart, dayEnd)) {
      result.push({
        start: addDays(dayStart, 1),
        end: roundedEnd,
        day: addDays(dayStart, 1),
      });
    } else {
      result.push({
        start: localStart,
        end: dayEnd,
        day: dayStart,
      });
      result.push({
        start: addDays(dayStart, 1),
        end: roundedEnd,
        day: addDays(dayStart, 1),
      });
    }
  } else {
    result.push({
      start: localStart,
      end: roundedEnd,
      day: dayStart,
    });
  }

  return result.filter(range => !isEqual(range.start, range.end));
};

export const processFreeTimeSlots = (freeHours: TFreeHours): TFreeHours => {
  const result: TFreeHours = {};
  Object.keys(freeHours).forEach((day, i) => {
    const date = new Date(day);
    const slots = freeHours[day];

    slots.forEach(slot => {
      const startUTC = parseTimeStringUTC(slot.range.starts, date);
      const endUTC = parseTimeStringUTC(slot.range.ends, date);

      splitTimeRange(startUTC, endUTC, date).forEach(range => {
        const isInvalidRange = isBefore(range.end, range.start);

        if (!isInvalidRange) {
          const dayKey = format(range.start, LOCALE_DATE_FORMAT);
          if (!result[dayKey]) {
            result[dayKey] = [];
          }

          const correctRangeEnd = format(range.end, LOCALE_TIME_FORMAT);

          result[dayKey].push({
            range: {
              starts: format(range.start, LOCALE_TIME_FORMAT),
              ends: correctRangeEnd === '00:00' ? '23:59' : correctRangeEnd,
            },
            limited: slot.limited,
            blocked: slot.blocked,
            blockedApptId: slot.blockedApptId,
          });
        }
      });
    });
  });

  return result;
};

export function checkMergedSlots(slots: TFreeTimeSlot[]) {
  const sortedSlots = slots.sort((a, b) => getTimeInMinutes(a.range.starts) - getTimeInMinutes(b.range.starts));
  const mergedRanges = [];

  for (const range of sortedSlots) {
    if (mergedRanges.length === 0) {
      mergedRanges.push(range);
    } else {
      const last = mergedRanges[mergedRanges.length - 1];
      if (getTimeInMinutes(last.range.ends) === getTimeInMinutes(range.range.starts)) {
        last.range.ends =
          getTimeInMinutes(range.range.ends) > getTimeInMinutes(last.range.ends) ? range.range.ends : last.range.ends;
      } else {
        mergedRanges.push(range);
      }
    }
  }
  return mergedRanges;
}

export function replaceFreeHoursForMergeCase(freeHours: TFreeHours) {
  const mergedFreeHours: TFreeHours = {};
  Object.keys(freeHours).forEach((day, i) => {
    const filteredDaySlots = freeHours[day].filter(slot => !slot.blocked);
    mergedFreeHours[day] = checkMergedSlots(filteredDaySlots);
  });
  return mergedFreeHours;
}

export function prepareFreeHours(freeHours: TFreeHours, selectedService: TFacilities) {
  const localizedFreeHours = processFreeTimeSlots(freeHours);

  const freeHoursWithMergedRanges = replaceFreeHoursForMergeCase(localizedFreeHours);

  const processedFreeHours: TConvertedFreeHours = {};

  for (const date in freeHoursWithMergedRanges) {
    if (freeHoursWithMergedRanges.hasOwnProperty(date)) {
      const timeSlots = freeHoursWithMergedRanges[date];
      processedFreeHours[date] = timeSlots.map(slot => ({
        ...slot,
        availableTimeSlots: getAvailableTimeSlots(date, slot, selectedService.duration),
      }));
    }
  }

  return processedFreeHours;
}
