import './PopUp.scss';

import PopUpButton from '../PopUpButton/PopUpButton';
import type { TPopupState } from '../../utils/types';
import { popupState } from '../../atoms';
import { useHandlers } from './hooks';
import { useRecoilState } from 'recoil';

const PopUp = () => {
  const [state, setState] = useRecoilState<TPopupState>(popupState);

  const { handleClick } = useHandlers({ setState });

  if (!state.popup) return <></>;

  return (
    <div className='popUpWrapper'>
      <div className='popUp'>
        {!!state.popup?.icon && <img src={state.popup.icon} alt='popUp icon' />}
        <p>{state.popup.text}</p>
        <div className='actions'>
          {state.popup.actions?.map((action, i) => <PopUpButton action={action} handleClick={handleClick} key={i} />)}
        </div>
      </div>
    </div>
  );
};

export default PopUp;
