import './Notification.scss';

import type { TNotification, TNotificationState } from '../../utils/types';
import { useCallback, useEffect } from 'react';

import NotificationProvider from '../NotificationProvider/NotificationProvider';
import { notificationState } from '../../atoms';
import { useRecoilState } from 'recoil';
import { useRemoveNotification } from '../../utils';

const Notification = () => {
  const [state] = useRecoilState<TNotificationState>(notificationState);

  const removeNotification = useRemoveNotification();

  const handleClose = useCallback(
    (notification: TNotification): void => {
      removeNotification(notification.id);
    },
    [removeNotification],
  );

  useEffect(() => {
    if (state.notifications.length > 4) {
      handleClose(state.notifications[0]);
    }
  }, [handleClose, state]);

  return (
    <div className='notification'>
      {state.notifications.map((notification, i) => (
        <NotificationProvider notification={notification} handleClose={handleClose} key={i} />
      ))}
    </div>
  );
};

export default Notification;
