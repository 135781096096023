import './ExpertInfoMini.scss';

import defaultExpertAvatar from '../../assets/images/entrepreneurs.png';
import { useGetExpertData } from '../../utils';

const ExpertInfoMini = () => {
  const { expertInfo, expertAvatar } = useGetExpertData();

  return (
    <div className='expertMiniInfo'>
      <img
        src={!!expertAvatar ? `data:image/png;base64,${expertAvatar}` : defaultExpertAvatar}
        alt='expert-avatar'
        className='avatar'
      />
      <div className='expertDescr'>
        <p className='title'>{expertInfo?.userName}</p>
        <p>{expertInfo?.profession}</p>
      </div>
    </div>
  );
};

export default ExpertInfoMini;
