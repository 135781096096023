import 'react-phone-input-2/lib/style.css';
import './PhoneNumberInput.scss';

import { AbsoluteRoutes, allowedCountriesList, getCapitalizeWord, useTranslation } from '../../utils';
import { ErrorMessage, Form, useFormikContext } from 'formik';

import { NavLink } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

export type TPhoneNumberInputProps = {
  name: string;
};

const PhoneNumberInput = () => {
  const { setFieldValue, values } = useFormikContext<{ phoneNumber: string }>();

  const { t } = useTranslation();

  return (
    <>
      <h2>{getCapitalizeWord(t('Login.Button_login'))}</h2>
      <Form className='form'>
        <div className='form-group'>
          <label htmlFor='phoneNumber'>{t('Login.Title_of_the_field_phone')}</label>
          <PhoneInput
            country='us'
            value={values.phoneNumber}
            onlyCountries={allowedCountriesList}
            onChange={phone => setFieldValue('phoneNumber', phone)}
            inputProps={{
              name: 'phoneNumber',
              required: true,
            }}
            containerClass='phone-input'
            inputClass='form-control'
          />
          <ErrorMessage name='phoneNumber' component='div' className='error-message' />
        </div>
        <button type='submit'>{t('Login.Button_login')}</button>
      </Form>
      <div className='bottom-link'>
        <p>{t('Login.Text_field')}</p>
        <NavLink to={AbsoluteRoutes.signup}>{t('Login.Button_sign_up')}</NavLink>
      </div>
    </>
  );
};

export default PhoneNumberInput;
