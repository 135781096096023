import 'react-phone-input-2/lib/style.css';
import './SignUpForm.scss';

import { AbsoluteRoutes, allowedCountriesList, get, useTranslation } from '../../utils';
import { ErrorMessage, Field, Form, useFormikContext } from 'formik';
import type { TCities, TForm } from '../../utils/types';
import { useEffect, useState } from 'react';

import { CitySelector } from '../CitySelector';
import { NavLink } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { activeRequestsAtom } from '../../atoms';
import { useSetRecoilState } from 'recoil';

const SignUpForm = () => {
  const [country, setCountry] = useState<string>('');
  const [cities, setCities] = useState<TCities[] | undefined>();
  const setActiveRequests = useSetRecoilState(activeRequestsAtom);
  const { setFieldValue } = useFormikContext<TForm>();

  const { t } = useTranslation();

  async function getCities(code: string, name: string) {
    const upperCaseCode = code.toLocaleUpperCase();
    if (upperCaseCode === country) return;
    setActiveRequests(prev => prev + 1);
    setFieldValue('country', name);
    const { data, error } = await get('CITIES', upperCaseCode);
    if (!!data) {
      setCities(data as TCities[]);
    } else {
      // need to add error handler
      console.log(error);
    }
    setActiveRequests(prev => prev - 1);
    setCountry(upperCaseCode);
  }

  useEffect(() => {
    getCities('US', 'United States'); // as a default value - TEMPORARY
    // only when mounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className='formHeader'>{t('Verification 2.Button_sign_up')}</h2>
      <Form className='form'>
        <div className='form-group'>
          <label htmlFor='fullName'>{t('Profile (expert).Text_full_name')}</label>
          <Field id='fullName' name='fullName' className='form-control' placeholder={t('Sign up (client).Username')} />
          <ErrorMessage name='fullName' component='div' className='error-message' />
        </div>

        <div className='form-group'>
          <label htmlFor='country'>
            {t('Sign up (client).Select_country')}
            <br />
            {t('Sign up (client).Full_number')}
          </label>
          <PhoneInput
            country='us'
            onlyCountries={allowedCountriesList}
            inputProps={{
              name: 'phone',
              required: true,
            }}
            onChange={(phone, country) => {
              setFieldValue('phoneNumber', phone);
              //@ts-ignore
              getCities(country.countryCode, country.name);
            }}
            containerClass='phone-input'
            inputClass='form-control'
          />
          <ErrorMessage name='phoneNumber' component='div' className='error-message' />
        </div>

        {!!cities && (
          <div className='form-group'>
            <Field component={CitySelector} name='cityCode' options={cities} initialValue='' />
            <ErrorMessage name='cityCode' component='div' className='error-message' />
          </div>
        )}

        <div className='form-group'>
          <label htmlFor='email'>{t('Sign up (client).Field_email')}</label>
          <Field
            id='email'
            name='email'
            type='email'
            className='form-control'
            placeholder={t('Sign up (client).Example_email')}
          />
          <ErrorMessage name='email' component='div' className='error-message' />
        </div>

        <button type='submit'>{t('Verification 2.Button_sign_up')}</button>
      </Form>
      <div className='bottom-link'>
        <p>{t('Sign up (client).Text_already_have_account_web')}</p>
        <NavLink to={AbsoluteRoutes.login}>{t('Sign up (client).Text_go_to_login_web')}</NavLink>
      </div>
    </>
  );
};

export default SignUpForm;
