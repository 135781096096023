import type { TVerificationCode, TVerificationInputHandlersProps } from '../../../utils/types';

import { useCallback } from 'react';

export function useHandlers({
  inputRefs,
  errorMessage,
  setErrorMessage,
  code,
  dispatch,
  onVerify,
}: TVerificationInputHandlersProps) {
  const focusOnNext = useCallback(
    (index: number) => {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
        inputRefs.current[index + 1]?.select();
      } else inputRefs.current[index]?.blur();
    },
    [inputRefs],
  );

  const hideErrorMessage = useCallback(() => {
    if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  }, [errorMessage, setErrorMessage]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      hideErrorMessage();
      const value = e.target.value.replace(/[^0-9]/g, '');

      if (value.length <= 1) {
        // Update the specific digit in the code
        const fieldName = `codeDigit${index + 1}` as keyof TVerificationCode;
        dispatch({ [fieldName]: value });

        if (value.length === 1 && index <= inputRefs.current.length - 1) {
          focusOnNext(index);
        }
      }
    },
    [dispatch, focusOnNext, hideErrorMessage, inputRefs],
  );

  const handleVerify = useCallback(() => {
    hideErrorMessage();
    const codeString = Object.values(code).join('');
    const codeLength = codeString.length;

    switch (codeLength) {
      case 0:
        setErrorMessage('Verification code is required');
        break;
      case 6:
        onVerify(codeString);
        break;
      default:
        setErrorMessage('Code must be exactly 6 digits');
        break;
    }
  }, [code, hideErrorMessage, onVerify, setErrorMessage]);

  return { hideErrorMessage, handleInputChange, handleVerify };
}
