import {
  DEFAULT_TIME_ZONE,
  LOCALE_DATE_FORMAT,
  LOCALE_DATE_WITH_TIME_FORMAT,
  LOCALE_TIME_FORMAT,
  is12hourFormat,
  timeZone,
} from '../constants';
import type { TFreeTimeSlot, TTimeRangeWithUTC } from '../types';
import { addMinutes, differenceInMinutes, format, parse } from 'date-fns';
import { formatInTimeZone, fromZonedTime, toZonedTime, format as tzFormat } from 'date-fns-tz';

import { LocalStorageKeys } from '../enums';
import { LocalStorageService } from '../services';

const durationStringRegEx = /PT(?:(\d+)H)?(?:(\d+)M)?/;

export function parseDurationToUIString(duration: string) {
  const matches = duration.match(durationStringRegEx);

  const hours = matches?.[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches?.[2] ? parseInt(matches[2], 10) : 0;

  return `0${hours}:${!!minutes ? minutes : '00'}`;
}

export function parseDurationToMinutesCount(duration: string) {
  const matches = duration.match(durationStringRegEx);

  const hours = matches?.[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches?.[2] ? parseInt(matches[2], 10) : 0;

  return hours * 60 + minutes;
}

// cose we work with selected TZ
export const convertFromTimeZoneToUTC = (dateString: Date): string => {
  const zonedDate = fromZonedTime(new Date(dateString), timeZone);

  return formatInTimeZone(zonedDate, DEFAULT_TIME_ZONE, LOCALE_DATE_WITH_TIME_FORMAT);
};

export const checkEndSlotTime = (date: Date) => {
  const minutes = date.getMinutes();
  const hours = date.getHours();

  if (hours === 0 && minutes === 0) {
    return new Date(date).setMinutes(minutes - 1);
  }

  return date;
};

export function getAvailableTimeSlots(date: string, slot: TFreeTimeSlot, durationString: string) {
  const slots: TTimeRangeWithUTC[] = [];
  const duration = parseDurationToMinutesCount(durationString);
  const { starts, ends } = slot.range;

  const startTime = parse(starts, LOCALE_TIME_FORMAT, new Date());
  const endTime = parse(ends, LOCALE_TIME_FORMAT, new Date());

  const timeInMinutes = differenceInMinutes(endTime, startTime);

  const totalMinutes = timeInMinutes % 10 !== 0 ? timeInMinutes + 1 : timeInMinutes;

  for (let i = 0; i <= totalMinutes - duration; i += duration) {
    const slotStart = addMinutes(startTime, i);
    const slotEnd = addMinutes(slotStart, duration);

    const endUTCDate = new Date(`${date}T${format(checkEndSlotTime(slotEnd), LOCALE_TIME_FORMAT)}:00`);

    slots.push({
      starts: format(slotStart, LOCALE_TIME_FORMAT),
      ends: format(checkEndSlotTime(slotEnd), LOCALE_TIME_FORMAT),
      startsUTC: convertFromTimeZoneToUTC(new Date(`${date}T${format(slotStart, LOCALE_TIME_FORMAT)}:00`)),
      endsUTC: convertFromTimeZoneToUTC(endUTCDate.getMinutes() % 2 !== 0 ? addMinutes(endUTCDate, 1) : endUTCDate),
      moveBack: Boolean(i),
      moveForward: totalMinutes - duration - i > 30,
    });
  }
  return slots;
}

export function getDateInLocalTZ(inputDate: string) {
  const [date, time] = inputDate.split(' ');
  const fullDate = `${date}T${time}:00Z`;

  const zonedDate = toZonedTime(fullDate, timeZone);

  const localDate = tzFormat(zonedDate, LOCALE_DATE_FORMAT, { timeZone });
  const localTime = getLocalTimeString(tzFormat(zonedDate, LOCALE_TIME_FORMAT, { timeZone }));

  return {
    localDate,
    localTime,
  };
}

export function setTimeZone(timeZone: string) {
  const currentTZ = LocalStorageService.getItem(LocalStorageKeys.TimeZone);

  if (!!currentTZ && currentTZ === timeZone) {
    return;
  }

  LocalStorageService.setItem(LocalStorageKeys.TimeZone, timeZone);
}

export function convertTo12HourFormat(time: string) {
  const [hour, minute] = time.split(':').map(Number);

  const period = hour >= 12 ? 'PM' : 'AM';
  const adjustedHour = hour % 12 || 12;

  return `${adjustedHour}:${minute < 10 ? '0' + minute : minute} ${period}`;
}

export function getLocalTimeString(time: string) {
  if (is12hourFormat) {
    return convertTo12HourFormat(time);
  } else {
    return time;
  }
}

export function getTimeInMinutes(time: string) {
  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 + Number(minutes);
}

export function getMovedTimeString(timeString: string, moveBy: number) {
  const [hours, minutes] = timeString.split(':').map(Number);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  date.setMinutes(date.getMinutes() + moveBy);

  const updatedHours = String(date.getHours()).padStart(2, '0');
  const updatedMinutes = String(date.getMinutes()).padStart(2, '0');
  return `${updatedHours}:${updatedMinutes}`;
}

export function moveApptAtRequestDateString(date: String, moveBy: number) {
  const dateTime = new Date(`${date.replace(' ', 'T')}:00.000Z`);

  dateTime.setMinutes(dateTime.getMinutes() + moveBy);

  return dateTime.toISOString().slice(0, 16).replace('T', ' ');
}
