import './WorkHours.scss';

import { daysOrder, getCapitalizeWord, getLocalTimeString, useTranslation } from '../../utils';

import type { TDaySchedule } from '../../utils/types';
import { fullDataAtom } from '../../atoms';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

const WorkHours = () => {
  const fullExpertData = useRecoilValue(fullDataAtom);
  const { t } = useTranslation();

  const workHoursData: (TDaySchedule & { day: string })[] = useMemo(() => {
    if (!fullExpertData) return [];
    const workHoursArray = [];
    const { schedule } = fullExpertData.workHours;

    for (const [key, value] of Object.entries(schedule)) {
      workHoursArray.push({ ...value, day: key });
    }

    const sortedWorkHoursArray = workHoursArray.sort((a, b) => {
      return daysOrder[a.day as keyof typeof daysOrder] - daysOrder[b.day as keyof typeof daysOrder];
    });
    return sortedWorkHoursArray;
  }, [fullExpertData]);

  if (!fullDataAtom) return <></>;

  return (
    <div className='scheduleContainer'>
      <h3 className='title'>{t('Settings (expert).Tab_workhours')}</h3>
      <div className='dayList'>
        {workHoursData.map((schedule, i) => {
          return (
            <div className={`scheduleItem ${schedule.enabled ? '' : 'dayOff'}`} key={i}>
              <span>{getCapitalizeWord(t(`Days.${getCapitalizeWord(schedule.day)}`))}</span>
              {schedule.enabled ? (
                <div>
                  {schedule.ranges.map((range, i) => (
                    <span key={i} className='scheduleRange'>
                      {getLocalTimeString(range.starts)} - {getLocalTimeString(range.ends)}
                    </span>
                  ))}
                </div>
              ) : (
                <span>{t('Settings (expert).Text_day_off')}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkHours;
