import type { TPopUpButtonProps } from '../../utils/types';

const PopUpButton = ({ action, handleClick }: TPopUpButtonProps) => {
  const { onClick, text, color } = action;

  return (
    <button style={{ background: color }} onClick={() => handleClick(onClick)}>
      {text}
    </button>
  );
};

export default PopUpButton;
