import './TopLogo.scss';

import logo from '../../assets/images/logo-dark.png';

const TopLogo = () => {
  const goToMainPage = () => {
    window.open('https://quick-appointment.com/', '_blank');
  };

  return (
    <div className='topLogo'>
      <img src={logo} alt='logo' className='logo' onClick={goToMainPage} />
    </div>
  );
};

export default TopLogo;
