import './assets/fonts/Roboto-Black.ttf';
import './assets/fonts/Roboto-BlackItalic.ttf';
import './assets/fonts/Roboto-Bold.ttf';
import './assets/fonts/Roboto-BoldItalic.ttf';
import './assets/fonts/Roboto-Italic.ttf';
import './assets/fonts/Roboto-Light.ttf';
import './assets/fonts/Roboto-LightItalic.ttf';
import './assets/fonts/Roboto-Medium.ttf';
import './assets/fonts/Roboto-MediumItalic.ttf';
import './assets/fonts/Roboto-Regular.ttf';
import './assets/fonts/Roboto-Thin.ttf';
import './assets/fonts/Roboto-ThinItalic.ttf';
import './index.scss';

import AppRouter from './AppRouter';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import reportWebVitals from './reportWebVitals';

const appHeight = (): void => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <RecoilRoot>
    <AppRouter />
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
