import React, { useCallback, useState } from 'react';

import { searchRegex } from '../../../utils';
import type { TCitiesSelectorHandlerProps } from '../../../utils/types';

export function useHandlers({ form, field, initialValue }: TCitiesSelectorHandlerProps) {
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const openSelect = useCallback(() => {
    setOpen(true);
  }, []);
  const closeSelect = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOptionClick = useCallback(
    (selectedValue: React.SetStateAction<string>, id: number) => {
      closeSelect();
      setSelectedValue(selectedValue);
      setSearchValue('');
      form.setFieldValue(field.name, id);
      field.name === 'cityCode' && form.setFieldValue('cityString', selectedValue);
    },
    [closeSelect, field.name, form],
  );

  const handleSearchInputChange = useCallback((value: string) => {
    setSearchValue(value.replace(searchRegex, ''));
  }, []);

  const onOptionClick = useCallback(
    (name: string, id: number) => {
      return () => handleOptionClick(name, id);
    },
    [handleOptionClick],
  );

  return {
    isOpen,
    openSelect,
    handleSearchInputChange,
    selectedValue,
    searchValue,
    onOptionClick,
  };
}
