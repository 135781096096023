import './SearchInput.scss';

import { type ChangeEvent, useState } from 'react';

import { searchRegex } from '../../utils';
import type { TSearchInputProps } from '../../utils/types';

const SearchInput = ({ handleChangeSearchValue }: TSearchInputProps) => {
  const [search, setSearch] = useState<string>('');
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value.replace(searchRegex, ''));
    handleChangeSearchValue?.(e.target.value);
  };

  return (
    <div className='searchInput'>
      <input type='search' placeholder='Search...' value={search} autoComplete='off' onChange={handleInputChange} />
      <span className='searchInput_icon'> </span>
    </div>
  );
};

export default SearchInput;
