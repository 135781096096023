import './NextButton.scss';

import { TNextButtonProps } from '../../utils/types';
import { useNavigate } from 'react-router-dom';

const NextButton = ({ link, btnText, isDisable, navState }: TNextButtonProps) => {
  const navigate = useNavigate();

  const goToCreateAppointment = () => {
    navigate(link, { state: navState });
  };

  return (
    <div className='addButton'>
      <button onClick={goToCreateAppointment} disabled={!!isDisable}>
        {btnText}
      </button>
    </div>
  );
};

export default NextButton;
