export const initialSignUpFormValues = {
  fullName: '',
  phoneNumber: '',
  cityCode: undefined,
  email: '',
  cityString: '',
  country: '',
};

export const initialPhoneFormValues = (defaultValue = '') => {
  return {
    phoneNumber: defaultValue,
  };
};

export const verificationCodeInitialValues = {
  codeDigit1: '',
  codeDigit2: '',
  codeDigit3: '',
  codeDigit4: '',
  codeDigit5: '',
  codeDigit6: '',
};
