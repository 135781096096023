import './AppointmentList.scss';

import { getDateInLocalTZ, useTranslation } from '../../utils';
import { useData, useHandlers } from './hooks';

const AppointmentList = () => {
  const { apptList } = useData();
  const { onApptClick } = useHandlers({ apptList });
  const { t } = useTranslation();

  // because title looking like - "Coming appointments \n(no coming appointments)\nTap to refresh"
  const title = t('Dashboard (client).Text_field_coming_appointments').split('\n')[0];

  if (!apptList?.length) return <></>;

  return (
    <>
      <h3>{title}</h3>
      <div className='list'>
        {apptList.map((appt, i) => {
          const { localDate, localTime } = getDateInLocalTZ(appt.startDate);
          return (
            <div key={i} data-appt-id={appt.appointmentId} className='appointment' onClick={onApptClick}>
              <div>
                <span>{t('New appointment (client).Text_date')}</span>
                <span>{localDate}</span>
              </div>
              <div>
                <span>{t('New appointment (client).Text_time_opt_2')}</span>
                <span>{localTime}</span>
              </div>
              <div>
                <span>{t('New appointment (client).Text_status')}</span>
                <span className={appt.type}>{t(`Confirmation.Text_${appt.type.toLocaleLowerCase()}`)}</span>
              </div>
              <div>
                <span>{t('New appointment (client).Text_expert')}</span>
                <span>{appt.serviceProviderName}</span>
              </div>
              <div>
                <span>{t('Login.Title_of_the_field_phone')}</span>
                <span>{appt.serviceProviderPhoneNumber}</span>
              </div>
              <div>
                <span>{t('Confirmation.Text_service')}</span>
                <span>{appt.expertFacility.facility}</span>
              </div>
              <div>
                <span>{t('Settings (expert).Text_location')}</span>
                <span className='location'>{appt.geolocation.fullAddress}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default AppointmentList;
