import {
  LocalStorageKeys,
  LocalStorageService,
  formatDateForRequest,
  getLastDayOfMonthForRequest,
  post,
} from '../../../utils';
import type { TAppointment, TApptListResponse, TApptStatus } from '../../../utils/types';
import { useEffect, useState } from 'react';

export function useData() {
  const [apptList, setApptList] = useState<TAppointment[]>([]);

  const today = new Date();

  const requestData = {
    dateRange: {
      from: `${formatDateForRequest(today)} 00:00`,
      to: `${getLastDayOfMonthForRequest(today)} 23:59`,
    },
    status: ['PENDING', 'APPROVED'],
  };

  const getApptList = async () => {
    if (!LocalStorageService.getItem(LocalStorageKeys.AccessToken)) return;
    const { data, error } = await post('LIST_APPOINTMENT', requestData);
    if (error) {
      console.log(error);
    } else if (data) {
      const responseData = data as TApptListResponse;
      const approvedApptsWithStatus = (responseData.data.APPROVED || []).map(appt => ({
        ...appt,
        type: 'APPROVED' as TApptStatus,
      }));

      const pendingApptsWithStatus = (responseData.data.PENDING || []).map(appt => ({
        ...appt,
        type: 'PENDING' as TApptStatus,
      }));

      const allAppointments = [...approvedApptsWithStatus, ...pendingApptsWithStatus];

      const sortedApptList = allAppointments.sort((a, b) => {
        const [aDate, aTime] = a.startDate.split(' ');
        const [bDate, bTime] = b.startDate.split(' ');

        return new Date(`${aDate}T${aTime}:00Z`).getTime() - new Date(`${bDate}T${bTime}:00Z`).getTime();
      });

      setApptList(sortedApptList);
    }
  };

  useEffect(
    () => {
      getApptList();
    },
    // only when mounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { apptList };
}
